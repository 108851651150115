<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                active
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Actas en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Validez del Acta PREP
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Validez del Acta PREP
          </h2>

          
          <div class="row p-contenedor-30">
            <div class="col-lg-12">
              <p class="f-s-24"> Para validar la autenticidad del Acta PREP, se utiliza un software lector de código único de imagen, <strong>Código de integridad</strong> o <strong>Código HASH.</strong></p>
            </div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-12 text-center imagenes-30"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-1.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-12 imagenes-30">
              <h2 class="f-s-sub f-w-500 text-center"> ¿Qué es el Código de integridad o Código HASH? </h2>
              <p class="f-s-24 text-left"> Es el valor o código de representación único que permite identificar a cada imagen del Acta PREP digitalizada. Dicho hash o código es obtenido a partir de algún algoritmo criptográfico que se aplica a cualquier tipo de archivo, denominado comúnmente funciones hash. </p>
              <p class="f-s-24 text-left"> Mediante este código, puedes verificar si la imagen del Acta: </p>
            </div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-4 col-12 list-stilo imagenes-70">
              <ul class="f-s-24 text-left">
                <li> Fue manipulada con algún software de edición de imágenes. </li>
              </ul>
            </div>
            <div class="col-12 d-lg-none d-block text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/imagenes.png" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-4 col-12 list-stilo imagenes-70">
              <ul class="f-s-24 text-left">
                <li> Tuvo cualquier intento de alteración del archivo. </li>
              </ul>
            </div>
            <div class="col-12 col-12 d-lg-none d-block text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-3.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-4 list-stilo imagenes-70">
              <ul class="f-s-24 text-left">
                <li> Es legitima, así como su información. </li>
              </ul>
            </div>
            <div class="col-12 d-lg-none d-block text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-4.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30">
            <div class="col-lg-4 list-stilo imagenes-70 text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/imagenes.png" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-4 list-stilo imagenes-70 text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-3.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-4 list-stilo imagenes-70 text-center"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-4.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 m-t-20">
            <div class="col-lg-12 text-center imagenes-30">
              <h2 class="f-s-sub f-w-500"> ¿Cómo validar si la imagen del Acta PREP no fue alterada? </h2>
            </div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r p-b-20"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-1.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-5 col-10 sn-p-l">
              <p class="f-s-24 f-w-500"> Descarga cualquier lector de Código SHA* (HASH256) utilizado por el PREP. </p>
            </div>
            <div class="col-lg-6 text-center imagenes-70"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-5.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-6 text-center imagenes-70 d-lg-block d-none"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-6.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r p-b-20"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-2.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-5 col-10 sn-p-l">
              <p class="f-s-24 f-w-500"> En el sitio PREP 2024, ubica el Acta de la casilla y descárgala. </p>
            </div>
            <div class="col-lg-6 text-center imagenes-70 d-lg-none d-block"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-6.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r p-b-20"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-3.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-5 col-10 list-stilo sn-p-l">
              <p class="f-s-24 f-w-500"> Genera el Código HASH: </p>
              <p class="f-s-24"> a.	Abre el lector de Código HASH256 </p>
              <p class="f-s-24"> b.	Sube el archivo de la imagen del Acta PREP </p>
              <p class="f-s-24"> c.	Sigue la secuencia: File-Select File </p>
              <p class="f-s-24"> d.	Selecciona la opción HASH256 </p>
              <p class="f-s-24"> e.	Elige el archivo y presiona abrir </p>
            </div>
            <div class="col-lg-6 text-center imagenes-70"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-7.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-6 text-center imagenes-70 d-lg-block d-none"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-8.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r p-b-50"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-4.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-5 col-10 sn-p-l">
              <p class="f-s-24 f-w-500"> Copia y pega el código, contenido en el archivo de resultados de la Base de Datos del PREP, en la columna SHA. </p>
            </div>
            <div class="col-lg-6 text-center imagenes-70 d-lg-none d-block"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-8.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20">
            <div class="col-lg-1 col-2 imagenes-50 text-right sn-p-r p-b-20"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-5.svg" class="img-fluid mx-auto d-block"></div>
            <div class="col-lg-5 col-10 sn-p-l">
              <p class="f-s-24 f-w-500"> Compara el código de la imagen del Acta con el que arroja el lector de Código HASH256 y comprueba su autenticidad. </p>
              <p class="f-s-24"> a. Todos los caracteres deben coincidir, sin importar mayúsculas o minúsculas. </p>
            </div>
            <div class="col-lg-6 text-center imagenes-70"><img src="https://ayuda.ine.mx/2024/informate/assets/img/prep/validez-9.svg" class="img-fluid mx-auto d-block"></div>
          </div>
          <div class="row p-contenedor-30 list-stilo m-t-20 m-b-50">
            <div class="col-lg-12 sn-m-b">
              <p class="f-s-16"> Nota: </p>
              <p class="f-s-16"> Si se realiza un cambio en el archivo, por mínimo que sea, el código se alteraría, por lo que tendríamos un código totalmente diferente. </p>
            </div>
          </div>
          <div class="row p-contenedor-30 m-b-50">
            <div class="col-lg-12 text-center f-s-16">
              <p class="f-w-500"> Referencias: </p>
              <p class="referencias"> · Anexo 13, Lineamientos del PREP, Capítulo Único y Capítulo II. </p>
            </div>
          </div>
  


          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Validez_Acta_PREP.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
